import React from "react";
import '../CSS/common.css';
import '../CSS/visualAttention.css';

function VisualAttention() {
    return(
        <div id="container" className="va_container">
            <section className="main_wrap">
            <h2 className="blind">메인비주얼</h2>
            <div className="main_visual_title">
                <img src="../image/visualAttention_visual2.png" alt="" />
                <p>집중도 분석 엔진</p>
                <span>
                학습장좌의 상호작용이 제한된 이러닝 동영상에서<br />화면의 구성요소와 교수자의 제스처<br /> 그리고, 학습자의 주의집중, 비언어적 수업 활동 분석으로 집중도를 분석합니다.
                <br />
                </span>
                <span className="comment">
                (한국교원대학교 2014논문 - 집중영역에 대한 피드백으로 평균 점수가 향상됨)
                </span>
            </div>
            </section>
            <section className="va_section1">
                <div className="com_inner section1_inner">
                    <img src="../image/visualAttention_section2.png" alt="" />
                </div>
            </section>
            <section className="va_section2">
                <div className="com_inner section2_inner">
                    <h3 className="section_title small">EXPERIMENTS</h3>
                    <h3 className="section_title blue">교육에 적용하면</h3>
                    <div className="section2_box">
                        <ul>
                            <li>
                                <img src="../image/visualAttention_section1-1.png" alt="" />
                                <div className="content_txt">
                                    <p>시선추적</p>
                                    <span>
                                    학습자 또는 시청자의 시선이<br /> 어디를 향하고 있는지.<br /> 시선을 추적합니다.
                                    </span>
                                </div>
                            </li>
                            <li>
                                <img src="../image/visualAttention_section1-2.png" alt="" />
                                <div className="content_txt">
                                    <p>집중도 코칭</p>
                                    <span>아이가 스스로 공부할 수 있게.<br /> 현실적인 코칭</span>
                                </div>
                            </li>
                            <li>
                                <img src="../image/visualAttention_section1-3.png" alt="" />
                                <div className="content_txt">
                                    <p>차영상 분석</p>
                                    <span>AOI - Area Of Interest<br />집중해야 하는 영역을 미리<br />분석해 놓습니다.</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="va_section3">
                <div className="com_inner section3_inner">
                    <h3 className="section_title small">EXPERIMENTS</h3>
                    <h3 className="section_title">온라인 쇼핑몰에 적용하면</h3>
                    <div className="section4_imgBox">
                        <img src="../image/visualAttention_section4.png" alt="" />
                    </div>
                </div>
            </section>
            <section className="emotion_section4 va_section4">
                <div className="com_inner section4_inner">
                    <video src="../image/미디어1.mp4" controls />
                </div>
            </section>
            <section className="com_experience">
                <img src="../image/visualAttention-footer_modify.png" alt="" />
                <button type="button" className="btn_demo margin_top">
                Coming Soon
                </button>
            </section>
        </div>
    );
}

export default VisualAttention;