import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../CSS/common.css';
import '../CSS/home.css';


function Home() {

	const rightFixRef = useRef(null);

	useEffect(() => {
		const rightFix = rightFixRef.current;

		const handleScroll = () => {
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			if (scrollTop >= 1930) {
				rightFix.classList.add('on');
			} else {
				rightFix.classList.remove('on');
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [rightFixRef]);



	return (
		<div id="container" className="sub_container">
			<section className="main_wrap">
				<h2 className="blind">메인비주얼</h2>
				<div className="main_visual_title white_title">
					<p>듣고, 보고, 말하고</p>
					<p>우리에게 필요한 기술을</p>
					<p>만들어 나갑니다.</p>
				</div>
			</section>
			<section className="main_section1">
				<div className="com_inner section1_inner">
					<h3 className="section_title">우리가 하는 일들</h3>
					<ul>
						<li>
							<Link to="">
								<img src="../image/main_section1_img1.png" alt="" />
								<h3 className="section_txt">Emotion</h3>
								<div className="txt_box">
									<p className="section_txt">감정은 못 속여!</p>
									<p className="section_txt">얼굴의 표현을 읽어요</p>
								</div>
							</Link>
						</li>
						<li style={{ float: 'right' }}>
							<Link to="" style={{ padding: '30px 0 10px 40px' }}>
								<img src="../image/main_section1_img2.png" alt="" style={{ width: '250px' }} />
								<h3 className="section_txt">Visual Attention</h3>
								<div className="txt_box">
									<p className="section_txt">웹캠 앞에서의</p>
									<p className="section_txt">자세, 얼굴, 시선 집중도 분석</p>
									<p className="section_txt">그리고 코칭을 해요.</p>
								</div>
							</Link>
						</li>
						<li>
							<Link to="">
								<img src="../image/main_section1_img3.png" alt="" style={{ width: '280px' }} />
								<h3 className="section_txt">Voice</h3>
								<div className="txt_box">
									<p className="section_txt">틀린 대본 잘못된 녹음</p>
									<p className="section_txt">손쉽게 변환 가능해요.</p>
									<p className="section_txt" style={{ marginTop: '20px' }}>성우 필요없이</p>
									<p className="section_txt">음성을 만들 수 있어요.</p>
								</div>
							</Link>
						</li>
						<li style={{ float: 'right' }}>
							<Link to="" style={{ paddingBottom: '60px' }}>
								<img src="/image/main_section1_img4.png" alt="" style={{ width: '270px' }} />
								<h3 className="section_txt">Detection</h3>
								<div className="txt_box">
									<p className="section_txt">이미지, 영상에서</p>
									<p className="section_txt">정보를 찾고, 분석해서</p>
									<p className="section_txt">사람의 노고를 줄여요.</p>
								</div>
							</Link>
						</li>
					</ul>
				</div>
			</section>
			<section className="main_section2">
				<div className="com_inner section2_inner">
					<h2
						className="section_title"
						style={{ textAlign: "left" }}
					>
						우리를 대신해<br />할 수 있는 일들은 이렇습니다.
					</h2>
					<div className="section2_box">
						<div className="section2_content">
							<img src="/image/main_section2_img1.png" alt="" />
							<div className="section2_txt">
								<h3 className="section2_tit">우리 아이의<br />자기주도학습 코칭</h3>
								<p>스스로 학습하는 능력을 기르는 것</p>
								<p>학부모가 간절히 바라고,</p>
								<p>우리 아이에게 가장 필요한 것</p>
							</div>
						</div>
						<div className="section2_content">
							<div className="section2_txt">
								<h3 className="section2_tit yellow">시청자, 소비자의<br />감정을 알고 싶어</h3>
								<p>쇼핑몰의 물건을 보고 있는 소비자의 감정.</p>
								<p>내가 만든 영상을 시청하는 시청자의 감정.</p>
								<p>학습자의 지루함. 감정의 변화.</p>
							</div>
							<img src="/image/main_section2_img2.png" alt="" />
						</div>
						<div className="section2_content">
							<img src="/image/main_section2_img3.png" alt="" />
							<div className="section2_txt">
								<h3 className="section2_tit purple">나 대신 읽고, 말하고</h3>
								<p>잘못된 녹음, 내가 원하는 문장을 읽어주고,</p>
								<p>챗봇 서비스에 음성까지 더해서,</p>
								<p>키보드 대신에 말로....</p>
							</div>
						</div>
						<div className="section2_content">
							<div className="section2_txt">
								<h3 className="section2_tit green">저 물고기의 무게는?<br />자동차 계기판의 주행거리는</h3>
								<p>어떤 물체를 찾고, 갯수를 파악하거나, 길이 무게를 측정.</p>
								<p>또는, 특정 이미지에서 문자, 숫자를 추출하는 것</p>
								<p>사람이 일일이 하기 힘든 일들.</p>
							</div>
							<img src="/image/main_section2_img4.png" alt="" />
						</div>
					</div>
				</div>
				<div id="right_fix" className="main_right_txt" ref={rightFixRef}>
					<p>
						<span>그럼<br />우리는<br /></span>더욱<br />가치있는<br />인간적인<br />일을<br />할 수<br />있습니다.
					</p>
				</div>
				<div className="square_wrap">
					<span className="square square1"></span>
					<span className="square square2"></span>
					<span className="square square3"></span>
					<span className="square square4"></span>
					<span className="square square5"></span>
				</div>
			</section>
		</div>
	);
}

export default Home;
