import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import logo from '../image/IO-VISION_logo.png';
import '../CSS/common.css';


function Header() {
	// 헤더 스크롤
	const [scroll, setScroll] = useState(0);

	useEffect(() => {
		let header = document.querySelector("#header");

		const handleMouseEnter = () => {
			header.classList.add("on");
		};

		const handleMouseLeave = () => {
			if (scroll === 0) {
				header.classList.remove("on");
			}
		};

		header.addEventListener("mouseenter", handleMouseEnter);
		header.addEventListener("mouseleave", handleMouseLeave);

		return () => {
			header.removeEventListener("mouseenter", handleMouseEnter);
			header.removeEventListener("mouseleave", handleMouseLeave);
		};
	}, [scroll]);

	useEffect(() => {
		const handleScroll = () => {
			setScroll(window.pageYOffset);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	// gnb 페이지마다 글자 색상 다르게
	const location = useLocation();
	const [isDarkColor, setIsDarkColor] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0); // 활성화된 인덱스 상태

	useEffect(() => {
		setIsDarkColor(location.pathname.includes('home') || location.pathname.includes('inquiry'));

		const storedIndex = sessionStorage.getItem('activeIndex');
		if (storedIndex) {
			setActiveIndex(parseInt(storedIndex, 10));
		}

		const paths = ['/home', '/emotion', '/visual-attention', '/voice', '/detection', '/caption-generation', '/inquiry'];
		const currentIndex = paths.findIndex(path => location.pathname.startsWith(path));
		if (currentIndex !== -1) {
			setActiveIndex(currentIndex);
			sessionStorage.setItem('activeIndex', currentIndex.toString());
		}

	}, [location, location.pathname]);

	return (
		<header id="header" className={scroll >= 100 ? "on" : ""}>
			<div className="header_inner">
				<h1 className={`logo ${activeIndex === 0 ? 'active' : ''}`}>
					<Link to="/home"><img src={logo} alt="" /></Link>
				</h1>
				<nav className="gnb">
					<ul className="depth1">
						<li className={activeIndex === 1 ? 'active' : ''}>
							<Link to="/emotion" style={{ color: isDarkColor ? "#fff" : "#000" }}>Emotion</Link>
						</li>
						<li className={activeIndex === 2 ? 'active' : ''}>
							<Link to="/visual-attention" style={{ color: isDarkColor ? "#fff" : "#000" }}>Visual Attention</Link>
						</li>
						<li className={activeIndex === 3 ? 'active' : ''}>
							<Link to="/voice" style={{ color: isDarkColor ? "#fff" : "#000" }}>Voice</Link>
						</li>
						<li className={activeIndex === 4 ? 'active' : ''}>
							<Link to="/detection" style={{ color: isDarkColor ? "#fff" : "#000" }}>Detection</Link>
						</li>
						<li className={activeIndex === 5 ? 'active' : ''}>
							<Link to="/caption-generation" style={{ color: isDarkColor ? "#fff" : "#000" }}>Captioning&amp;Generation</Link>
						</li>
						<li className={activeIndex === 6 ? 'active' : ''}>
							<Link to="/inquiry" style={{ color: isDarkColor ? "#fff" : "#000" }}>기술 및 견적문의</Link>
						</li>
					</ul>
				</nav>
			</div>
		</header>
	);
}

export default Header;