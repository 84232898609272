import React from "react";
import '../CSS/common.css';
import '../CSS/detection.css';

function Detection() {
    return (
        <div id="container" className="detection_container cg_container">
            <section className="main_wrap">
                <h2 className="blind">메인비주얼</h2>
                <div className="main_visual_title">
                    <img src="../image/detection_visual2.png" alt="" />
                    <p>개체추출 엔진</p>
                    <span> 감정 분석을 통해, 시청자 (학습자)가 보이는 감정 자체가 <br /> 학습 전달 내용에 얼마나 Engaged 되어 있는가를 분석할 수 있습니다. <br /> 다차원의 개념이며 개인의 의지, 메타인지, 집단공유인지, 유대감, <br />
                    소속감을 표현합니다.</span>
                </div>
            </section>
            <section className="detection_section1 cg_section1">
                <div className="com_inner detection_section1_inner cg_section1_inner">
                    <h3 className="section_title blue">특장점</h3>
                    <h4 className="section_sub_title">
                        <img src="./image/emotion_icon-metro-quote-1.png" alt="" className="left_quote" />
                        목적에 맞는 최적화된 모델
                        <img src="./image/icon-metro-quote-copy-2.png" alt="" className="right_quote" />
                    </h4>
                    <div className="section1_content">
                        <img src="./image/group-9.png" alt="" />
                        <p className="section1_content_txt">IO-VISION Detection 솔루션은 다양한 목적에 맞게 인공지능 모델과 커스트마이징을 제공합니다<br />
                            제조공장에서의 불량판단, 생산량 예측, 이미지에서의 개체 추출, 개체의 부가정보 추출, <br />전자문서의 텍스트 인식 및 테이블인식 저장, 영상에서의 개체인식등으로 활용할 수 있습니다.</p>
                    </div>
                </div>
            </section>
            <section className="detection_section2">
                <div className="com_inner detection_section2_inner">
                    <div className="section2_box">
                        <div className="section2_content">
                            <img src="./image/detaction-section2-img1.png" alt="" />
                            <div className="section2_txt">
                                <h3 className="section2_tit">Detection</h3>
                                <div className="sub_txt">
                                    <p>
                                    사전에 학습된 이미지 및 영상에서의 개체 레이블 데이터를 학습하여
                                    입력된 프레임에서 원하는 개체를 추출합니다.
                                    </p>
                                    <p>
                                    개체를 추출할때 위치, 면적, 두께, 갯수, 크기, 길이, 무게등을 예측할
                                    수 있습니다. 또한, 머신러닝과 딥러닝 모델을 이용하여 생산량등의 예측,
                                    불량예측 등에 활용할 수 있습니다.{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="section2_content">
                            <div className="section2_txt" style={{ left: "50px" }}>
                                <h3 className="section2_tit">Classification</h3>
                                <div className="sub_txt">
                                    <p>
                                    고객 요구 및 필요에 의해 만들어진 레이블을 학습하여 이미지를 분류합니다.
                                    추가로 범용 학습데이터를 이용하여 이미지와 영상등에서 기본 개체를 추출
                                    합니다.{" "}
                                    </p>
                                    <p>
                                    또한, 머신러닝과 딥러닝 모델을 목적에 맞게 개발하여 다양한 산업군에서
                                    불량 판단등으로 활용할 수 있습니다.{" "}
                                    </p>
                                </div>
                            </div>
                            <img src="./image/detaction-section2-img2.png" alt="" />
                        </div>
                        <div className="section2_content">
                            <img src="./image/detaction-section2-img3.png" alt="" />
                            <div className="section2_txt" style={{ right: "50px" }}>
                                <h3 className="section2_tit">OCR</h3>
                                <div className="sub_txt">
                                    <p>
                                    문자, 태양광, 숫자, 번호판, 영수증, 신분증등을 인식하고 포함되어 있는
                                    의미있는 캐릭터를 추출합니다.{" "}
                                    </p>
                                    <p>
                                    또한, 전자문서에 포함되어 있는 통계표의 값을 데이터베이스에 적재할 수
                                    있도록 합니다.{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="detection_section3">
                <div className="com_inner section3_inner">
                    <h4 className="section_title small">EXPERIMENTS</h4>
                    <h3 className="section_title">활용사례</h3>
                    <ul className="section3_box">
                        <li className="section3_content">
                            <img src="./image/detaction-section3-img1.png" alt="" />
                            <div className="section3_content_txt">
                                <p>Image Detection &#38; Classification</p>
                                <span>공장, 농장, 선박등의 환경에서<br />작업자의 안전을 위해 CCTV에서<br /> 촬영된 영상에서 작업자의 위험 행동을 검출하고<br /> 생산물의 종류를 분류하고 부가정보를 추출합니다.</span>
                            </div>
                        </li>
                        <li className="section3_content">
                            <img src="./image/detaction-section3-img2.png" alt="" />
                            <div className="section3_content_txt">
                                <p>Image<br /> Classification</p>
                                <span>최신 인공지능 사전학습 모델등을 활용하여<br />생산공정의 불량 및 비정상 상태를 분류합니다.<br />학습데이터를 구축하기 힘든 환경에서는<br />생성모델을 이용하여 정상데이터에서<br />비정상 데이터 분류가 가능합니다. </span>
                            </div>
                        </li>
                        <li className="section3_content">
                            <img src="./image/detaction-section3-img3.png" alt="" />
                            <div className="section3_content_txt">
                                <p>OCR</p>
                                <span>이미지와 영상에서의 사람이 직접 해야하는<br />캐릭터 데이터 라벨링 작업, 숫자 및 문자 추출등을<br />OCR 기술을 통해 자동화할 수 있습니다.<br />자동차 번호판, 주행거리, 책장의 도서명 및<br />출판사 정보등을 자동 추출할 수 있습니다. </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
            <section className="com_experience">
                <img src="./image/detection_bottom_modify.png" alt="" />
                <button type="button" className="btn_demo">Coming Soon</button>
            </section>
        </div>
    );
}

export default Detection;