import React, { useState } from "react";
import '../CSS/common.css';
import '../CSS/inquiry.css';
import axios from "axios";

function Inquiry() {

    const [company, setCompany] = useState('');
    const [requester, setRequester] = useState('');
    const [userName, setUserName] = useState('');
    const [position, setPosition] = useState('');
    const [email, setEmail] = useState('');
    const [userTel, setUserTel] = useState('');
    const [solutionType, setSolutionType] = useState('');
    const [questionTitle, setQuestionTitle] = useState('');
    const [content, setContent] = useState('');
    const [privacyPolicyYn, setPrivacyPolicyYn] = useState(false);
    const [newsletterYn, setNewsletterYn] = useState(false);
    const [productIntroductionYn, setProductIntroductionYn] = useState(false);
    // input placeholder on/off
    const [isInputClicked, setIsInputClicked] = useState('');
    // input 필수입력값 체크
    const [validation, setValidation] = useState('');
    // 메일 전송시 스피너
    const [isLoadingMayfarm, setIsLoadingMayfarm] = useState(false);


    function handleCheckboxChange(e) {
        const { name, checked } = e.target;

        switch (name) {
            case 'privacyPolicyYn':
                setPrivacyPolicyYn(checked);
                break;
            case 'newsletterYn':
                setNewsletterYn(checked);
                break;
            case 'productIntroductionYn':
                setProductIntroductionYn(checked);
                break;
            default:
                break;
        }
    };



function sendMail(e) {
    e.preventDefault();

    if (!company) { setValidation(alert('회사명을 입력해주세요.')); return false; }
    if (!userName) { setValidation(alert('성함을 입력해주세요.')); return false; }
    if (!email) { setValidation(alert('이메일을 입력해주세요.')); return false; }
    if (!userTel) { setValidation(alert('연락처를 입력해주세요.')); return false; }
    if (!solutionType) { setValidation(alert('제품을 선택해주세요.')); return false; }
    if (!questionTitle) { setValidation(alert('제목을 입력해주세요.')); return false; }
    if (!content) { setValidation(alert('내용을 입력해주세요.')); return false; }
    if (!privacyPolicyYn) { setValidation(alert('개인정보 수집 및 이용 동의는 필수입니다.')); return false; }
    if (!newsletterYn) { setValidation(alert('비정기적 뉴스레터 수신은 필수입니다.')); return false; }
    if (!productIntroductionYn) { setValidation(alert('제품 소개서는 필수입니다.')); return false; }

    setIsLoadingMayfarm(true);

    // 메이팜소프트 홈페이지 메일폼 API 사용함
    const obj = {
        companyName: company,
        questionType: "Q",
        routes: requester,
        requester: userName,
        position: position,
        email: email,
        phone: userTel,
        solutionType: solutionType,
        questionTitle: questionTitle,
        content: content,
        privacyPolicyYn: privacyPolicyYn ? 1 : 0,
        newsletterYn: newsletterYn ? 1 : 0,
        productIntroductionYn: productIntroductionYn ? 1 : 0
    }

    const requestBody = JSON.stringify(obj);
    axios
        .post(process.env.REACT_APP_MAIL_API_URL, requestBody, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            if (response.status === 200) {
                alert('견적 문의 요청이 완료되었습니다.');
            } else {
                alert('견적 문의 요청이 실패하였습니다.');
                // window.location.reload();
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        })
        .finally(() => {
            setIsLoadingMayfarm(false);
        });
}

return (
    <div id="container" className="sub_container inquiry_container">
        <section className="main_wrap">
            <h2 className="blind">메인비주얼</h2>
            <div className="main_visual_title white_title">
                <p>기술 및 견적 문의</p>
                <span>메이팜소프트는 다양한 자연어처리, 머신러닝/딥러닝 기술을 활용하여<br />고민하고 계시는 문제, 구현하고자 하는 서비스를 실현할 수 있도록 합니다.</span>
            </div>
        </section>
        <section className="main_section1 inquiry_section1">
            <div className="com_inner section1_inner">
                <h3 className="section_title">Contact</h3>
                <div className="inquiry_wrap">
                    <div className="tbl_inquiry">
                        <form onSubmit={sendMail}>
                            <table>
                                <caption>문의 작성하기 테이블</caption>
                                <tbody>
                                    <tr className="flex_basis">
                                        <th>회사명 <span className="span_red">(필수)</span></th>
                                        <td>
                                            <input type="text"
                                                name="company"
                                                value={company}
                                                onChange={(e) => setCompany(e.target.value)}
                                                placeholder={isInputClicked === 'company' ? '' : '회사명'}
                                                onFocus={() => setIsInputClicked('company')}
                                                onBlur={() => {
                                                    setIsInputClicked('');
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="flex_basis">
                                        <th>어떻게 메이팜을 알게됐나요? (선택)</th>
                                        <td>
                                            <div className="select_mayfarm">
                                                <select
                                                    value={requester}
                                                    onChange={(e) => setRequester(e.target.value)}
                                                    name="user_contact">
                                                    <option value="">선택해주세요.</option>
                                                    <option value="검색">검색</option>
                                                    <option value="지인소개">지인소개</option>
                                                    <option value="뉴스">뉴스</option>
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="flex_basis">
                                        <th>성함 <span className="span_red">(필수)</span></th>
                                        <td>
                                            <input
                                                type="text"
                                                name="user_name"
                                                value={userName}
                                                onChange={(e) => setUserName(e.target.value)}
                                                placeholder={isInputClicked === 'userName' ? '' : '성함'}
                                                onFocus={() => setIsInputClicked('userName')}
                                                onBlur={() => {
                                                    setIsInputClicked('');
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="flex_basis">
                                        <th>직함 (선택)</th>
                                        <td>
                                            <input
                                                type="text"
                                                name="user_position"
                                                value={position}
                                                onChange={(e) => setPosition(e.target.value)}
                                                placeholder={isInputClicked === 'position' ? '' : '직함'}
                                                onFocus={() => setIsInputClicked('position')}
                                                onBlur={() => {
                                                    setIsInputClicked('');
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="flex_basis">
                                        <th>E-MAIL <span className="span_red">(필수)</span></th>
                                        <td>
                                            <input
                                                type="text"
                                                name="user_email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder={isInputClicked === 'email' ? '' : '답장 받을 메일 주소를 적어주세요.'}
                                                onFocus={() => setIsInputClicked('email')}
                                                onBlur={() => {
                                                    setIsInputClicked('');
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="flex_basis">
                                        <th>연락처 <span className="span_red">(필수)</span></th>
                                        <td>
                                            <input
                                                type="text"
                                                name="user_tel"
                                                value={userTel}
                                                onChange={(e) => setUserTel(e.target.value)}
                                                placeholder={isInputClicked === 'userTel' ? '' : '연락처'}
                                                onFocus={() => setIsInputClicked('userTel')}
                                                onBlur={() => {
                                                    setIsInputClicked('');
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="tbl_block" style={{ float: "none" }}>
                                        <th>제품선택 <span className="span_red">(필수)</span></th>
                                        <td>
                                            <div className="select_product">
                                                <select
                                                    value={solutionType}
                                                    onChange={(e) => setSolutionType(e.target.value)}
                                                    name="user_product">
                                                    <option value="">제품을 선택해주세요.</option>
                                                    <option value="이미지 캡션">이미지 캡션</option>
                                                    <option value="이미지 생성">이미지 생성</option>
                                                    <option value="음성합성 및 인식">음성합성 및 인식</option>
                                                    <option value="이미지 검출">이미지 검출</option>
                                                    <option value="기타">기타</option>
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="tbl_block" style={{ float: "none" }}>
                                        <th>문의사항을 적어주세요. <span className="span_red">(필수)</span></th>
                                        <td>
                                            <input
                                                type="text"
                                                name="user_title"
                                                value={questionTitle}
                                                onChange={(e) => setQuestionTitle(e.target.value)}
                                                placeholder={isInputClicked === 'questionTitle' ? '' : '제목을 입력해주세요.'}
                                                onFocus={() => setIsInputClicked('questionTitle')}
                                                onBlur={() => {
                                                    setIsInputClicked('');
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="tbl_block">
                                        <td>
                                            <textarea rows="3"
                                                name="user_content"
                                                value={content}
                                                onChange={(e) => setContent(e.target.value)}
                                                placeholder={isInputClicked === 'content' ? '' : "- 분석데이터 유형 (데이터베이스, 텍스트, 문서, 이미지등)\n- 사용자 수 : 예) 대국민으로 1일 10만명 예상\n- 예상되는 결과 화면 : 예) 검색결과 화면, 이미지 객체 추출 결과 화면"}
                                                onFocus={() => setIsInputClicked('content')}
                                                onBlur={() => {
                                                    setIsInputClicked('');
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="checked_wrap">
                                <label className="label_checked">
                                    <input 
                                    type="checkbox"
                                    className="input_checked" 
                                    name="privacyPolicyYn"
                                    checked={privacyPolicyYn}
                                    onChange={handleCheckboxChange}
                                    />개인정보 수집 및 이용에 동의합니다
                                </label>
                                <label className="label_checked">
                                    <input 
                                    type="checkbox"
                                    className="input_checked" 
                                    name="newsletterYn"
                                    checked={newsletterYn}
                                    onChange={handleCheckboxChange}
                                    />메이팜에서 보내드리는 비정기적 뉴스레터 수신에 동의합니다.
                                </label>
                                <label className="label_checked">
                                    <input 
                                    type="checkbox"
                                    className="input_checked"
                                    name="productIntroductionYn"
                                    checked={productIntroductionYn}
                                    onChange={handleCheckboxChange} 
                                    />제품 소개서를 신청합니다.
                                </label>
                            </div>
                            {isLoadingMayfarm &&
                                <div className="dim">
                                    <div className='loading'></div>
                                </div>
                            }
                        </form>
                        <div className="btn_wrap">
                            <button type="submit" id="sandMail" onClick={sendMail}>신청하기</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
);
}

export default Inquiry;