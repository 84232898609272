import React from "react";
import '../CSS/common.css';
import '../CSS/captionGeneration.css';

function CaptionGeneration() {
    return (
        <div id="container" className="emotion_container cg_container">
            <section className="main_wrap">
                <h2 className="blind">메인비주얼</h2>
                <div className="main_visual_title cg_title">
                    <img src="../image/CaptionGeneration-mainVisual-img.png" alt="" />
                    <p>인공지능의 특이점이<br />오려나요?</p>
                    <div className="sub_title_wrap">
                        <div className="left_sub_title">
                            <span>이미지 생성 기술</span>
                            <ul>
                                <li>&#45; Prompt (Text) to image 생성기술</li>
                                <li>&#45; Image to Image 생성 기술</li>
                                <li>&#45; image interpolation image generation</li>
                                <li>&#45; Generated image 2D &#45;&gt; 3D Trasnfer</li>
                            </ul>
                        </div>
                        <div className="_sub_title">
                            <span>이미지 캡셔닝 기술</span>
                            <ul>
                                <li>&#45; Image 캡션을 통한 이미지 검색 지원</li>
                                <li>&#45; Image 기반 Question &amp; Answering 지원</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cg_section1">
                <div className="com_inner cg_section1_inner">
                    <h3 className="section_title blue">특이점 &#45; <span>SINGULARITY</span></h3>
                    <h4 className="section_sub_title">
                        <img src="../image/emotion_icon-metro-quote-1.png" alt="" className="left_quote" />
                        강력하고 다양한 생성 모델 제공
                        <img src="../image/icon-metro-quote-copy-2.png" alt="" className="right_quote" />
                    </h4>
                    <div className="section1_content">
                        <img src="../image/group-9.png" alt="" />
                        <p className="section1_content_txt">
                            IO-VISION Generation 솔루션은 이미지에 내재된 의미를 찾아 텍스트로 캡셔닝하고
                            웹마케팅, 디자인, 학습이미지가공 및 구축, 의미기반 이미지 검색, 나만의 이미지 생성 서비스를 제공합니다.
                        </p>
                    </div>
                </div>
            </section>
            <section className="cg_section2">
                <div className="com_inner cg_section2_inner">
                    <img src="../image/CaptionGeneration-section2.png" alt="" />
                </div>
            </section>
            <section className="cg_section3">
                <div className="com_inner cg_section3_inner">
                    <div className="section3_content">
                        <div className="text_box">
                            <p className="text_box_tit">Generation</p>
                            <span className="text_box_txt">
                                Image generative models are a class of machine learning models
                                that learn to generate new images that are similar to a given set
                                of training images. These models are typically based on deep
                                neural networks and have gained a lot of attention in recent years
                                due to their ability to create highly realistic and diverse
                                images. (From OpenAI ChatGPT3)
                            </span>
                            <span className="text_box_txt">
                                GAN 과 같은 생성적적대신경망 모델과 Diffusion 확산 모델을 기반으로
                                유사한 이미지를 생성하거나, 다양한 (diversity) 이미지, 스타일을
                                반영한 이미지를 Prompt를 통해 생성할 수 있습니다.
                            </span>
                        </div>
                        <img src="../image/CaptionGeneration-section3.png" alt="" />
                    </div>
                </div>
            </section>
            <section className="cg_section4">
                <div className="com_inner cg_section4_inner">
                    <div className="section4_content">
                        <div className="text_box">
                            <p className="text_box_tit">Generation</p>
                            <span className="text_box_txt">
                                Image captioning is the task of generating a textual description of an image. An image captioning model typically takes an image as input and outputs a sentence or a sequence of words that describe the content of the image. Image captioning models are often based on deep learning techniques, particularly convolutional neural networks (CNNs) for image feature extraction and recurrent neural networks (RNNs) for language modeling.
                            </span>
                            <span className="text_box_txt">
                                대량의 학습데이터 (학습데이터에는 이미지와 이미지를 해설한 텍스트와 매핑되어 있음)를 학습하여, 이미지 요소와 텍스트의 관계의 특징을 추출합니다. 이를 통해, 이미지별 캡션 문장을 생성하고 저장하여, 이미지의 내재된 의미를 검색할 수 있도록 합니다.
                            </span>
                        </div>
                        <img src="../image/CaptionGeneration-section4.png" alt="" />
                    </div>
                </div>
            </section>
            <section className="com_experience cg_section5">
                <img src="../image/CaptionGeneration_bottom_modify.png" alt="" />
                <button type="button" className="btn_demo">Coming Soon</button>
            </section>
        </div>
    );
}

export default CaptionGeneration;