import { BrowserRouter, Routes, Route, } from 'react-router-dom';
import React from 'react';
import Header from './component/Header';
import Footer from './component/Footer';
import Home from './container/Home';
import Emotion from './container/Emotion';
import VisualAttention from './container/VisualAttention';
import Voice from './container/Voice';
import Detection from './container/Detection';
import CaptionGeneration from './container/CaptionGeneration';
import Inquiry from './container/Inquiry';
import ScrollToTop from './component/ScrollToTop';
import './CSS/common.css';

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<ScrollToTop />
				<Header />
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route path="/home" element={<Home />} />
					<Route path="/emotion" element={<Emotion />} />
					<Route path="/visual-attention" element={<VisualAttention />} />
					<Route path="/voice" element={<Voice />} />
					<Route path="/detection" element={<Detection />} />
					<Route path="/caption-generation" element={<CaptionGeneration />} />
					<Route path="/inquiry" element={<Inquiry />} />
				</Routes>
				<Footer />
			</BrowserRouter>
		</div>
	);
}

export default App;
