import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../CSS/common.css';
import mayfarmLogo from '../image/mayfarm_logo_300x56.png';


function Footer() {

    // 패밀리사이트 클릭
    const [isListOpen, setIsListOpen] = useState(false);
    const handleFamilySiteClick = () => {
        setIsListOpen(!isListOpen);
    } 

    return (
        <footer id="footer">
            <h2 className="mayfarm_logo">
                <img src={mayfarmLogo} alt="" />
            </h2>
            <div className="com_inner footer_inner">
                <div className='footer_left'>
                    <h4>본사</h4>
                    <div className="footer_address">
                        <dl style={{ marginBottom: "10px" }}>
                            <dt>기술기획컨설팅 본부</dt>
                            {/* <dt>언어처리</dt> */}
                            <dd>서울시 송파구 법원로 11길 25 H비지니스파크 A동 1108호</dd>
                        </dl>
                        <dl>
                            <dt>기술개발수행 본부</dt>
                            {/* <dt>컴퓨터비전, 인공지능</dt> */}
                            <dd>서울시 송파구 중대로 105 가락아이디타워</dd>
                        </dl>
                    </div>
                </div>
                <div className='footer_right'>
                    <dl className="footer_tel">
                        <dt style={{ marginRight: "10px" }}>대표번호</dt>
                        <dd>02-514-5547</dd>
                    </dl>
                    <div className='family_site'>
                        <button className={isListOpen ? 'btn_family on' : 'btn_family'} onClick={handleFamilySiteClick}>Family Site</button>
                        <ul className='family_list' style={{display: isListOpen ? 'block' : 'none',}}>
                            <li>
                                <Link to="https://www.mayfarm.co.kr/" target='blank'>(주)메이팜소프트</Link>
                            </li>
                            <li>
                                <Link to="https://tikatalk.co.kr/" target='blank'>티카톡</Link>
                            </li>
                            <li>
                                <Link to="https://www.mayfarm.co.kr/service_moazoom.html" target='blank'>모아줌</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;