import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../CSS/common.css';
import '../CSS/voice.css';

function Voice() {
    // const [showPopup, setShowPopup] = useState(false); //구글네이버 팝업
    const [activeTab, setActiveTab] = useState('tab_one'); //음성인식데모 탭

    // 파일 업로드
    const [uploadFile, setUploadFile] = useState("");
    const [audioUrl, setAudioUrl] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [fileUploadText, setFileUploadText] = useState(false);
    const audioRef = useRef(null);

    // 음성 녹음하고 듣기 버튼 누르면 텍스트 뿌려줌
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [mikeText, setMikeText] = useState(null);
    const [chunks, setChunks] = useState([]);

    // 구글 STT config 설정
    // const [googleSttEncoding, setGoogleSttEncoding] = useState("");
    // const [googleSttSampleRateHertz, setGoogleSttSampleRateHertz] = useState(48000);
    // const [googleSttLanguageCode, setGoogleSttLanguageCode] = useState("");

    // 샘플파일
    const [isSamplePlaying, setIsSamplePlaying] = useState(false);
    const [sampleAudioUrl, setSampleAudioUrl] = useState(null);
    const [sampleAudioBase64File, setSampleAudioBase64File] = useState('');
    const [sampleMayfarmText, setSampleMayfarmText] = useState('');
    // const [googleSttResult, setGoogleSttResult] = useState('');
    const sampleAudioRef = useRef(null);

    // 음성합성데모 TTS
    // const [manSpeed, setManSpeed] = useState(0);
    // const [manPitch, setManPitch] = useState(0);
    const [womanSpeed, setWomanSpeed] = useState(0);
    const [womanPitch, setWomanPitch] = useState(0);

    // 음성합성데모 스피커 4가지
    const [womenTopSpeakText, setWomenTopSpeakText] = useState("");
    // const [womenBottomSpeakText, setWomenBottomSpeakText] = useState("");
    // const [manTopSpeakText, setManTopSpeakText] = useState("");
    // const [manBottomSpeakText, setManBottomSpeakText] = useState("");

    // 스피너(네이버 추가 예정)
    // const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
    const [isLoadingMayfarm, setIsLoadingMayfarm] = useState(false);
    // const [womenBottomLoading, setWomenBottomLoading] = useState(false);
    // const [manTopLoading, setManTopLoading] = useState(false);
    // const [manBottomLoading, setManBottomLoading] = useState(false);

    const [audioSrc, setAudioSrc] = useState(''); //재생할 음성의 URL 저장

    // const [validation, setValidation] = useState('');


    const [isTtsPlaying, setIsTtsPlaying] = useState({
        playButton1: false,
        playButton2: false,
        playButton3: false,
        playButton4: false,
    }); // 음성 재생 여부

    // 버튼 이미지 생성
    const playButtonImage = isPlaying ? "./image/icon_pause.png" : "./image/icon_play.png";
    const samplePlayButtonImage = isSamplePlaying ? "./image/mic-icon_pausepng.png" : "./image/mic-icon_paly.png";
    const mikePlayButtonImage = isRecording ? "./image/recording.gif" : "./image/mic-icon.png"

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };


    const TabClick = (event) => {
        setActiveTab(event.target.id);
    };

    // const PopupOpen = () => {
    //     setShowPopup(true);
    // };

    // const PopupClose = () => {
    //     setShowPopup(false);
    // }

    // function handleManSpeedChange(e) {
    //     setManSpeed(e.target.value);
    // }

    // function handleManPitchChange(e) {
    //     setManPitch(e.target.value);
    // }
    function handleWomanSpeedChange(e) {
        const speed = parseFloat(e.target.value);
        setWomanSpeed(speed);
    }

    function handleWomanPitchChange(e) {
        const pitch = parseFloat(e.target.value);
        setWomanPitch(pitch);
    }


    useEffect(() => {
        setSampleAudioUrl("../audio/upfiles1_c1552a19d76dee76be7a56116b414ad90.wav");
        // setGoogleSttEncoding("ENCODING_UNSPECIFIED");
        // setGoogleSttSampleRateHertz(48000);
        // setGoogleSttLanguageCode("ko-KR");

        // 샘플 파일 base64 인코딩
        const XMLrequest = new XMLHttpRequest();
        XMLrequest.open("GET", sampleAudioUrl, true);
        XMLrequest.responseType = "arraybuffer";
        XMLrequest.onload = () => {
            const audioData = XMLrequest.response;
            setSampleAudioBase64File(
                btoa(new Uint8Array(audioData).reduce((data, byte) => data + String.fromCharCode(byte), ''))
            );
        };
        XMLrequest.send();

        // 마이크 테스트
        if (mediaRecorder) {
            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    setChunks((prevState) => [...prevState, event.data]);
                }
            };
        }

    }, [sampleAudioUrl, isRecording, mediaRecorder]);

    // 샘플 파일 재생 버튼 이벤트
    const sampleAudioPlay = async () => {
        if (!sampleAudioRef.current) {
            return;
        }
        if (!isSamplePlaying) {
            const response = await fetch(sampleAudioUrl);
            const audioData = await response.arrayBuffer();
            let sampleFile = new File([new Uint8Array(audioData)], 'sample.wav', { type: "audio/wav" });
            await sendAudioFile(sampleAudioBase64File, sampleFile, "sample");
            sampleAudioRef.current.play();
        } else {
            sampleAudioRef.current.pause();
        }
        setIsSamplePlaying(!isSamplePlaying);
    };

    // 파일 업로드 이벤트
    async function handleFileInputChange(event) {
        const selectedFile = event.target.files[0];
        const allowedExtensions = /(\.mp3|\.wav|\.ogg|\.mp4)$/i;
        if (!allowedExtensions.exec(selectedFile.name)) {
            alert("오디오 파일만 가능합니다.");
            event.target.value = "";
            setUploadFile(null);
            setAudioUrl(null);
        } else {
            setUploadFile(selectedFile);
            setAudioUrl(URL.createObjectURL(selectedFile));
            setIsFileUpload(true);
            document.getElementById("inputUploadFile").value = selectedFile.name;
        }
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => resolve(event.target.result);
            reader.onerror = (error) => reject(error);
        });
    }

    // STT 호출(구글, 메이팜) 구글 주석처리
    async function sendAudioFile(base64file, file, source) {
        // setIsLoadingGoogle(true);

        // // 구글 STT 호출
        // const apiKey = process.env.REACT_APP_GOOGLE_STT_KEY
        // const googleUrl = `https://speech.googleapis.com/v1/speech:recognize?key=${apiKey}`;

        // const audio = {
        //     content: base64file,
        // };
        // const config = {
        //     encoding: googleSttEncoding,
        //     sampleRateHertz: googleSttSampleRateHertz,
        //     languageCode: googleSttLanguageCode,
        // };
        // const request = {
        //     audio: audio,
        //     config: config,
        // };

        // axios.post(googleUrl, request)
        //     .then(res => {
        //         if (res.status === 200) {
        //             let text = res.data.results[0].alternatives[0].transcript;
        //             setGoogleSttResult(text);
        //             console.log(res);
        //         } else {
        //             console.log(res);
        //         }
        //     })
        //     .catch(err => {
        //         console.log(err);
        //     })
        //     .finally(() => {
        //         setIsLoadingGoogle(false);
        //     });



        // 메이팜 STT 호출
        const url = process.env.REACT_APP_STT_API_URL;
        const formData = new FormData();
        formData.append('file', file);
        setIsLoadingMayfarm(true);

        axios.post(url, formData)
            .then(response => {
                if (response.data.code === 200) {
                    console.log(response);
                    let text = response.data.message;
                    if (source === "mike") {
                        setMikeText(text);
                    } else if (source === "sample") {
                        setSampleMayfarmText(text);
                    } else {
                        setFileUploadText(text);
                    }
                } else if (response.data.code === 400) {
                    alert(response.data.message);
                } else {
                    console.log(response);
                }
            })
            .catch(error => {
                console.log(error)
                alert(error);
            })
            .finally(() => {
                setIsLoadingMayfarm(false);
            });
    }

    // 파일 오디오 재생
    async function handlePlayButtonClick() {
        if (uploadFile === null || uploadFile === undefined) {
            alert("파일을 업로드 해주세요");
            return;
        }
        if (!audioRef.current) {
            return;
        }
        if (isFileUpload) {
            let sampleFileBase64 = "";
            await getBase64(uploadFile)
                .then(result => {
                    sampleFileBase64 = result;
                })
                .catch(err => {
                    console.log(err);
                });
            await sendAudioFile(sampleFileBase64.split("base64,")[1], uploadFile);
            setIsFileUpload(false);
        }
        if (!isPlaying) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
        setIsPlaying(!isPlaying);
    }

    function formatTime(time) {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        const milliseconds = Math.floor((time % 1) * 100);

        const formatTimeText = `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}.${milliseconds < 10 ? "00" : milliseconds < 10 ? "0" : ""}${milliseconds}`;
        document.getElementById("spanAudioTime").innerHTML = formatTimeText;
    }

    // 마이크 버튼 클릭
    const handleMicClick = () => {
        if (!isRecording) {
            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then((stream) => {
                    const mediaRecorder = new MediaRecorder(stream);
                    setMediaRecorder(mediaRecorder);
                    setIsRecording(true);
                    setChunks([]);
                    mediaRecorder.start();
                })
                .catch((error) => {
                    console.log(error);
                })
        } else {
            setIsRecording(false);
            mediaRecorder.stop();
        }
    };

    // 마이크 음성 데이터 저장
    const handleMikeSavePlay = async () => {
        setIsLoadingMayfarm(true);
        if (mediaRecorder && mediaRecorder.state === "recording") {
            mediaRecorder.stop();
            setIsRecording(false);
        }
        if (mediaRecorder === null) {
            alert("마이크 녹음 후 재생버튼을 클릭해주세요.");
            return;
        }

        let fileDate = new Date();
        const recordBlob = new Blob(chunks);
        // const recordFile = new File([recordBlob], fileDate.getTime()+'.wav', { type: recordBlob.type });
        const userAgent = navigator.userAgent.toLocaleLowerCase();
        let recordFile;
        if (userAgent.indexOf("iphone") > -1 || userAgent.indexOf("ipad") > -1) {
            recordFile = new File([recordBlob], fileDate.getTime()+'.ios', { type: recordBlob.type });
        } else {
            recordFile = new File([recordBlob], fileDate.getTime()+'.wav', { type: recordBlob.type });
        }
        let recordFileBase64 = "";
        await getBase64(recordFile)
            .then(result => {
                recordFileBase64 = result;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setIsLoadingMayfarm(false);
            })

        sendAudioFile(recordFileBase64.split("base64,")[1], recordFile, "mike");
    };

    // TTS 호출
    const voiceSynthesis = async (text, speaker, speed, pitch, setIsLoading) => {

        setIsLoading(true);

        const requestBody = JSON.stringify({ text, speaker, speed, pitch });
        try {
            const response = await axios.post(process.env.REACT_APP_TTS_API_URL, requestBody, {
                headers: {
                    "Content-Type": "application/json",
                }
            });
            if (response.data.code === 200) {
                console.log(response)
                setAudioSrc(response.data.data);
            }
        } catch (error) {
            alert("텍스트를 입력하여 주세요.");
        } finally {
            setIsLoading(false);
        }
    }

    // TTS 듣기
    const handleSynthesisListen = (button) => {
        if (audioSrc) {
            const audio = new Audio(audioSrc);
            audio.play();
            audio.addEventListener('ended', () => {
                setIsTtsPlaying((prevState) => ({
                    ...prevState,
                    [button]: false,
                }));
            });
            setIsTtsPlaying((prevState) => ({
                ...prevState,
                [button]: true,
            }));
        }
    };



    return (
        <div id="container" className="emotion_container va_container voice_container">
            <section className="main_wrap">
                <h2 className="blind">메인비주얼</h2>
                <div className="main_visual_title">
                    <img src="../image/voice_visual2.png" alt="" />
                    <p>음성 엔진</p>
                    <span>
                        음성인식과 음성합성 기술을 활용하여, <br />
                        대화형 서비스를 만들고, 기록할 수 있습니다. <br />
                        자체 인식 모델과 최신 학습모델, 지속적으로 업데이트 되는 <br />
                        다양한 학습 데이터를 통해 품질을 향상 시키고 있습니다.
                    </span>
                </div>
            </section>
            <section className="voice_section1">
                <div className="com_inner section1_inner">
                    <h3 className="section_title">음성 인식</h3>
                    <div className="section1_box">
                        <ul>
                            <li>
                                <img src="./image/voice_section1-1.png" alt="" />
                                <div className="content_txt">
                                    <p>AI 기반 자체 음성인식 모델</p>
                                    <span>음성인식, 특징추출, 후처리 과정의<br />자체 인식 모델 개발. 최신 음성인식<br />모델 연구 및 반영 </span>
                                </div>
                            </li>
                            <li>
                                <img src="./image/voice_section1-2.png" alt="" />
                                <div className="content_txt">
                                    <p>다양한 학습 데이터 반영</p>
                                    <span>AI HUB 및 해외 다양한<br />학습데이터 반영을 통한<br />인식 성능 향상</span>
                                </div>
                            </li>
                            <li>
                                <img src="./image/voice_section1-3.png" alt="" />
                                <div className="content_txt">
                                    <p>영샹 및 음성, 화자분리</p>
                                    <span>영상에서 음성 분리 및 저장<br />다화자 음성 분리 저장<br />텍스트 특징 추출 및 언어 처리<br />(IO-TA Text 분석 엔진 연동)</span>
                                </div>
                            </li>
                            <li>
                                <img src="./image/voice_section1-4.png" alt="" />
                                <div className="content_txt">
                                    <p>커스트마이징 지원</p>
                                    <span>고객 요구 산업군에 맞는 활용<br />고객 보유 지식베이스 및 사전 적용<br />설치형 서비스형 선택 적용<br />재학습 및 고객 전사 작업 지원</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="section1_box section_margin">
                        <h3 className="section_title small">Evaluation results</h3>
                        <table className="section1_table">
                            <thead>
                                <tr>
                                    <th className="table_th text_left">METRICS<br />MODEL</th>
                                    <th className="table_th bg_green">Eval<br />Clean</th>
                                    <th className="table_th bg_green">Eval<br />Clean</th>
                                    <th className="table_th bg_blue">Eval<br />Other</th>
                                    <th className="table_th bg_blue">Eval<br />Other</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="table_td"></td>
                                    <td className="table_td figure">WER(%)</td>
                                    <td className="table_td figure">CER(%)</td>
                                    <td className="table_td figure">WER(%)</td>
                                    <td className="table_td figure">CER(%)</td>
                                </tr>
                                <tr>
                                    <td className="table_td text_left">KSPONSPEECH PAPER</td>
                                    <td className="table_td"></td>
                                    <td className="table_td"></td>
                                    <td className="table_td"></td>
                                    <td className="table_td"></td>
                                </tr>
                                <tr>
                                    <td className="table_td text_left">&#45; RNN</td>
                                    <td className="table_td">23.2</td>
                                    <td className="table_td">8.6</td>
                                    <td className="table_td">28.3</td>
                                    <td className="table_td">9.8</td>
                                </tr>
                                <tr>
                                    <td className="table_td text_left">&#45; Transformer</td>
                                    <td className="table_td">21.9</td>
                                    <td className="table_td">8.0</td>
                                    <td className="table_td">26.6</td>
                                    <td className="table_td">9.0</td>
                                </tr>
                                <tr>
                                    <td className="table_td text_left">MAYFARM SOFT ( based on [4] )</td>
                                    <td className="table_td"></td>
                                    <td className="table_td"></td>
                                    <td className="table_td"></td>
                                    <td className="table_td"></td>
                                </tr>
                                <tr>
                                    <td className="table_td mayfarm_figure text_left">&#45; Transformer</td>
                                    <td className="table_td mayfarm_figure num">21.0</td>
                                    <td className="table_td mayfarm_figure num">7.5</td>
                                    <td className="table_td mayfarm_figure num">25.7</td>
                                    <td className="table_td mayfarm_figure num">8.4</td>
                                </tr>
                            </tbody>
                        </table>
                        <p className="info">KsponSpeech: Korean Spontaneous Speech Corpus for Automatic Speech Recognition
                            (Appl. Sci. 2020, 10, 6936. ETRI, MDPI)</p>
                    </div>
                </div>
            </section>
            <section className="va_section2 voice_section2">
                <div className="com_inner section2_inner">
                    <h3 className="section_title">음성인식 데모</h3>
                    <div className="voice_wrap voice_popup">
                        <div className="worko_tabs">
                            <input
                                className="state"
                                type="radio"
                                title="tab_one"
                                name="tabs_state"
                                id="tab_one"
                                checked={activeTab === 'tab_one'}
                                onChange={TabClick}
                            />
                            <input
                                className="state"
                                type="radio"
                                title="tab_two"
                                name="tabs_state"
                                id="tab_two"
                                checked={activeTab === 'tab_two'}
                                onChange={TabClick}
                            />
                            <input
                                className="state"
                                type="radio"
                                title="tab_three"
                                name="tabs_state"
                                id="tab_three"
                                checked={activeTab === 'tab_three'}
                                onChange={TabClick}
                            />
                            <input
                                className="state"
                                type="radio"
                                title="tab_four"
                                name="tabs_state"
                                id="tab_four"
                                checked={activeTab === 'tab_four'}
                                onChange={TabClick}
                            />
                            <div className="tabs flex_tabs tab_wrap">
                                <div className="label_wrap">
                                    <label
                                        htmlFor="tab_one"
                                        id="tab_one_label"
                                        className={`tab ${activeTab === 'tab_one' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('tab_one')}
                                    >
                                        마이크
                                    </label>
                                    <label
                                        htmlFor="tab_two"
                                        id="tab_two_label"
                                        className={`tab ${activeTab === 'tab_two' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('tab_two')}
                                    >
                                        파일 업로드
                                    </label>
                                    <label
                                        htmlFor="tab_three"
                                        id="tab_three_label"
                                        className={`tab ${activeTab === 'tab_three' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('tab_three')}
                                    >
                                        샘플 파일
                                    </label>
                                    <div className="file_upload">
                                        <input type="text" id="inputUploadFile" readOnly />
                                        <label>
                                            파일선택
                                            <input type="file" onChange={handleFileInputChange} />
                                        </label>
                                    </div>
                                    {/* <button
                                        type="button"
                                        id="opener"
                                        className="comparison_btn"
                                        onClick={PopupOpen}>
                                        구글/네이버 비교하기
                                    </button> */}
                                </div>
                                <div className="voice_left">
                                    <div
                                        id="tab_one_panel"
                                        className={`panel ${activeTab === 'tab_one' ? 'active' : ''}`}
                                    >
                                        <button className="btn_mike" onClick={handleMicClick}>
                                            <img src={mikePlayButtonImage} alt="" style={{width: "230px"}}/>
                                        </button>
                                        <button className='btn_save' onClick={handleMikeSavePlay}>
                                            <img src="./image/icon_play.png" alt="" />
                                        </button>
                                    </div>
                                    <div
                                        id="tab_two_panel"
                                        className={`panel ${activeTab === 'tab_two' ? 'active' : ''}`}
                                    >
                                        <div className="voice_spt">
                                            <div className="spectrum"></div>
                                            <input type="file" onChange={handleFileInputChange} accept=".mp3,.wav,.ogg,.mp4" />
                                            <div className="spectrum_play">
                                                <button className='btn_audioPlay' onClick={handlePlayButtonClick}>
                                                    <img src={playButtonImage} alt="" />
                                                </button>
                                                <span id='spanAudioTime'>00:00.00</span>
                                                <audio src={audioUrl} ref={audioRef} onEnded={() => setIsPlaying(false)} onTimeUpdate={() => formatTime(audioRef.current.currentTime)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="tab_three_panel"
                                        className={`panel ${activeTab === 'tab_three' ? 'active' : ''}`}
                                    >
                                        <button className="btn_sample_play" onClick={sampleAudioPlay}>
                                            <img src={samplePlayButtonImage} alt="" />
                                            <audio src={sampleAudioUrl} ref={sampleAudioRef} id='audioSample'></audio>
                                        </button>
                                    </div>
                                </div>
                                <div className="voice_right">
                                    <div className="txt_box voice_box loading_position">
                                        <p>{mikeText}</p>
                                        {isLoadingMayfarm &&
                                            <div className='loading'></div>
                                        }
                                        <span className='help_txt'>* 텍스트가 출력되는 시간은 약 3~5초 정도 소요됩니다.</span>
                                    </div>
                                    <div className="txt_box file_box loading_position">
                                        <p>{fileUploadText}</p>
                                        {isLoadingMayfarm &&
                                            <div className='loading'></div>
                                        }
                                        <span className='help_txt'>* 텍스트가 출력되는 시간은 약 3~5초 정도 소요됩니다.</span>
                                    </div>
                                    <div className="txt_box sample_box loading_position">
                                        <p>{sampleMayfarmText}</p>
                                        {isLoadingMayfarm &&
                                            <div className='loading'></div>
                                        }
                                        <span className='help_txt'> * 텍스트가 출력되는 시간은 약 3~5초 정도 소요됩니다.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {showPopup && (
                            <div className="popup_box">
                                <div className="popup_inner">
                                    <ul>
                                        <li>
                                            <div className="popup_tit mayfarm_top">
                                                <p>메이팜</p>
                                            </div>
                                            <div className="popup_btm mayfarm_btm loading_position">
                                                <div className="btm_inner">
                                                    <p>{sampleMayfarmText}</p>
                                                    {isLoadingMayfarm &&
                                                        <div id="mayfarmSpinner" className='loading'></div>
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="popup_tit google_top">
                                                <p>구글</p>
                                            </div>
                                            <div className="popup_btm google_btm loading_position">
                                                <div className="btm_inner">
                                                    <p>{googleSttResult}</p>
                                                    {isLoadingGoogle &&
                                                        <div id="googleSpinner" className='loading'></div>
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="popup_tit naver_top">
                                                <p>네이버</p>
                                            </div>
                                            <div className="popup_btm naver_btm loading_position">
                                                <div className="btm_inner">
                                                    <p>출력됨</p>
                                                    <div id="naverSpinner" className='loading'></div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <button type="button" className="btn_close" onClick={PopupClose}>
                                    <img src="./image/icon_close.png" alt="" />
                                </button>
                            </div>
                        )} */}
                    </div>
                    <p className="refer">* 크롬 브라우저에 최적화 되어 있습니다. </p>
                </div>
            </section>
            <section className="voice_section1 voice_section3">
                <div className="com_inner section1_inner section3_inner">
                    <h3 className="section_title">음성 합성</h3>
                    <div className="section1_box section3_box">
                        <ul>
                            <li>
                                <img src="./image/voice_section3-1.png" alt="" />
                                <div className="content_txt">
                                    <p>End to End Text to speech</p>
                                    <span>
                                        Zero-shot (poor-shot) 합성<br />
                                        최신 사전학습 모델 적용<br />
                                        자체 학습데이터 활용모델 제공
                                    </span>
                                </div>
                            </li>
                            <li>
                                <img src="./image/voice_section3-2.png" alt="" />
                                <div className="content_txt">
                                    <p>품질 향상 기법 적용</p>
                                    <span>
                                        언어처리를 통한 구문 생성<br />
                                        합성모델 Clean 문장 생성<br />
                                        멀티랭귀지 지원 (사전학습모델 활용)
                                    </span>
                                </div>
                            </li>
                            <li>
                                <img src="./image/voice_section3-3.png" alt="" />
                                <div className="content_txt">
                                    <p>기본 음성 제공</p>
                                    <span>
                                        여 1, 남 1 목소리 기본 제공<br />
                                        (공공기관등의 형식적 목소리)<br />
                                        별도의 학습데이터가 필요 없는<br />
                                        목소리 생성 제공
                                    </span>
                                </div>
                            </li>
                            <li>
                                <img src="./image/voice_section3-4.png" alt="" />
                                <div className="content_txt">
                                    <p>커스트마이징 지원</p>
                                    <span>
                                        서비스 형태 (웹,앱)지원<br />
                                        API 서비스 및 설치형 지원<br />
                                        서비스 관리를 위한 관리기 커스트마이징 지원
                                    </span>
                                </div>
                            </li>
                        </ul>
                        <div className="section3_img">
                            <img src="./image/voice-section3-img5.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="voice_section4">
                <div className="com_inner section4_inner">
                    <h3 className="section_title">음성합성 데모</h3>
                    <div className="voice_wrap">
                        <div className="voice_top">
                            <p> &#45; 숫자, 특수문자 그대로 읽도록 설정되어 있습니다. (예 &#45; ( ) -&gt; 괄호 열고 닫고)
                            </p>
                            <p> &#45; 불법 사용 방지를 위해 음성 워터마크 (메이팜소프트등의 특수 음성 삽입)
                            </p>
                        </div>
                        <div id="voice_woman" className="voice_woman">
                            <div className="voice_middle">
                                <div className="box_left">
                                    <div className="box_img">
                                        <img src="./image/voice_section4.png" alt="" />
                                    </div>
                                    <div className="btn_wrap">
                                        <button type="button">#사내방송</button>
                                        <button type="button">#오피셜</button>
                                        <button type="button">#차가운</button>
                                    </div>
                                </div>
                                <div className="box_middle">
                                    <div className="middle_inner">
                                        <span>스피드</span>
                                        <input
                                            type="range"
                                            min="-2"
                                            max="2"
                                            value={womanSpeed}
                                            onChange={handleWomanSpeedChange}
                                        />
                                    </div>
                                </div>
                                <div className="box_right">
                                    <div className="right_inner middle_inner">
                                        <span>피치</span>
                                        <input
                                            type="range"
                                            min="-2"
                                            max="2"
                                            value={womanPitch}
                                            onChange={handleWomanPitchChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="voice_bottom">
                                <div className="txt_box_wrap">
                                    <div className="txt_box loading_position">
                                        <textarea
                                            value={womenTopSpeakText}
                                            onChange={(e) => setWomenTopSpeakText(e.target.value)}
                                            name="user_man_speak"
                                            placeholder='텍스트를 입력해보세요'
                                        />
                                        {isLoadingMayfarm &&
                                            <div className='loading'></div>
                                        }
                                    </div>
                                    <div className='synthesis_btn_box'>
                                        <button type="button" onClick={() => {
                                            voiceSynthesis(womenTopSpeakText, 'MAYFARM VOICE (F)', womanSpeed, womanPitch, setIsLoadingMayfarm);
                                        }} className="btn_synthesis">
                                            생성
                                        </button>
                                        <button type='button' onClick={() => handleSynthesisListen('playButton1')} className={`btn_synthesis synthesis_listen ${isTtsPlaying.playButton1 ? 'playing' : ''
                                            }`}
                                        >
                                            {isTtsPlaying.playButton1 ? '재생 중' : '듣기'}
                                        </button>
                                    </div>
                                </div>
                                {/* <div className="txt_box_wrap margin">
                                    <div className="txt_box loading_position">
                                        <textarea
                                            value={womenBottomSpeakText}
                                            onChange={(e) => setWomenBottomSpeakText(e.target.value)}
                                            name="user_man_speak"
                                            placeholder='텍스트를 입력해보세요'
                                        />
                                        {womenBottomLoading &&
                                            <div className='loading'></div>
                                        }
                                    </div>
                                    <div className='synthesis_btn_box'>
                                        <button type="button" onClick={() => voiceSynthesis(womenBottomSpeakText, 'Female teacher 1', womanSpeed, womanPitch, setWomenBottomLoading)} className="btn_synthesis">
                                            생성
                                        </button>
                                        <button type='button'  onClick={() => handleSynthesisListen('playButton2')} className={`btn_synthesis synthesis_listen ${isTtsPlaying.playButton2 ? 'playing' : ''}`}>
                                            {isTtsPlaying.playButton2 ? '재생 중' : '듣기'}
                                        </button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        {/* <div id="voice_man" className="voice_man">
                            <div className="voice_middle">
                                <div className="box_left">
                                    <div className="box_img">
                                        <img src="./image/voice-section4-male.png" alt="" />
                                    </div>
                                    <div className="btn_wrap">
                                        <button type="button">#사내방송</button>
                                        <button type="button">#오피셜</button>
                                        <button type="button">#차가운</button>
                                    </div>
                                </div>
                                <div className="box_middle">
                                    <div className="middle_inner">
                                        <span>스피드</span>
                                        <input
                                            id="manSpeed"
                                            type="range"
                                            min="-2"
                                            max="2"
                                            value={manSpeed}
                                            onChange={handleManSpeedChange}
                                        />
                                    </div>
                                </div>
                                <div className="box_right">
                                    <div className="right_inner middle_inner">
                                        <span>피치</span>
                                        <input
                                            id="manPitch"
                                            type="range"
                                            min="-2"
                                            max="2"
                                            value={manPitch}
                                            onChange={handleManPitchChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="voice_bottom">
                                <div className="txt_box_wrap">
                                    <div className="txt_box loading_position">
                                        <textarea
                                            id="manTopSpeak"
                                            value={manTopSpeakText}
                                            onChange={(e) => setManTopSpeakText(e.target.value)}
                                            name="user_man_speak"
                                            placeholder='텍스트를 입력해보세요'
                                        />
                                        {manTopLoading &&
                                            <div className='loading'></div>
                                        }
                                    </div>
                                    <div className='synthesis_btn_box'>
                                        <button type="button" id="btnSpeakTest" onClick={() => voiceSynthesis(manTopSpeakText, 'Female teacher 2', manSpeed, manPitch, setManTopLoading)} className="btn_synthesis">
                                            생성
                                        </button>
                                        <button type='button'  onClick={() => handleSynthesisListen('playButton3')} className={`btn_synthesis synthesis_listen ${isTtsPlaying.playButton3 ? 'playing' : ''}`}
                                        >
                                            {isTtsPlaying.playButton3 ? '재생 중' : '듣기'}
                                        </button>
                                    </div>
                                </div>
                                <div className="txt_box_wrap margin">
                                    <div className="txt_box loading_position">
                                        <textarea
                                            id="manBottomSpeak"
                                            value={manBottomSpeakText}
                                            onChange={(e) => setManBottomSpeakText(e.target.value)}
                                            name="user_man_speak"
                                            placeholder='텍스트를 입력해보세요'
                                        />
                                        {manBottomLoading &&
                                            <div className='loading'></div>
                                        }
                                    </div>
                                    <div className='synthesis_btn_box'>
                                        <button type="button" onClick={() => voiceSynthesis(manBottomSpeakText, 'Male reader 1', manSpeed, manPitch, setManBottomLoading)} className="btn_synthesis">
                                            생성
                                        </button>
                                        <button type='button' onClick={() => handleSynthesisListen('playButton4')} className={`btn_synthesis synthesis_listen ${isTtsPlaying.playButton4 ? 'playing' : ''}`}
                                        >
                                            {isTtsPlaying.playButton4 ? '재생 중' : '듣기'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            <section className="voice_section5">
                <div className="com_inner section5_inner">
                    <h3 className="section_title small">EXPERIMENTS</h3>
                    <h3 className="section_title">교육에 적용하면</h3>
                    <div className="section5_box">
                        <div className="content">
                            <img src="./image/voice_section5-1.png" alt="" />
                            <div className="content_txt">
                                <p>말놀이</p>
                                <span>
                                    아이들이 단어를 공부하기 쉽게
                                    <br />
                                    음성으로 말놀이를 할 수 있도록 하였습니다.
                                </span>
                            </div>
                        </div>
                        <div className="content">
                            <img src="./image/voice_section5-2.png" alt="" />
                            <div className="content_txt">
                                <p>서술형 국어 문제 풀이</p>
                                <span>
                                    요즘, 긴 지문을 읽기 힘든 아이들이 많습니다.
                                    <br />
                                    지문을 읽고 문제를 풀 수 있도록 코칭하는 서비스를 개발하였습니다.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Voice;
