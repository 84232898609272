import React from "react";
import '../CSS/common.css';
import '../CSS/emotion.css';

function Emotion(props) {
    return (
        <div id="container" className="emotion_container">
            <section className="main_wrap">
                <h2 className="blind">메인비주얼</h2>
                <div className="main_visual_title">
                    <img src="../image/emotion_visual-2.png" alt="" />
                    <p>감정분석 엔진</p>
                    <span>
                    감정 분석을 통해, 시청자(학습자)가 보이는 감정 자체가
                    <br />
                    학습 전달 내용에 얼마나 Engaged 되어 있는가를 분석할 수 있습니다.
                    <br />
                    다차원의 개념이며 개인의 의지, 메타인지, 집단공유인지,
                    <br />
                    유대감, 소속감을 표현합니다.
                    </span>
                </div>
            </section>
            <section className="emotion_section1">
                <div className="com_inner section1_inner">
                    <ul className="section1_box">
                        <li className="section1_content">
                            <h4 className="section1_tit">EMOTION PSYCHOLOGY</h4>
                            <ul>
                                <li>
                                    <p className="left_sub_tit">
                                    미세감정 (Micro-expression)이론 (Paul Ekman 1934 ~ )
                                    </p>
                                    <span className="left_sub_txt">
                                    짧은 순간 얼굴 근육 변화로 숨겨진 감정을 얼굴 좌우 각각 40여개의
                                    근육 움직임으로 측정
                                    </span>
                                </li>
                                <li>
                                    <p className="left_sub_tit">FACS (Facial Action Coding System)</p>
                                    <span className="left_sub_txt">
                                    FACS (Facial Action Coding System){" "}
                                    </span>
                                </li>
                                <li>
                                    <p className="left_sub_tit">보편적 기본감정</p>
                                    <span className="left_sub_txt">
                                    행복, 놀람, 공포, 분노, 혐오, 슬픔
                                    </span>
                                </li>
                            </ul>
                        </li>
                        <li className="section1_content">
                            <h4 className="section1_tit">AFFECTIVE COMPUTING</h4>
                            <ul>
                                <li>
                                    <p className="right_sub_txt">
                                    감정과 관련되며, 감정으로부터 비롯되며, 감정에 영향을 주는
                                    컴퓨팅 (Rosalind Picard 1962 ~ )
                                    </p>
                                </li>
                                <li>
                                    <p className="right_sub_txt">상호적 관계 : 피질 + 변연계</p>
                                </li>
                                <li>
                                    <p className="right_sub_txt">
                                    감정은 주의집중을 조절하고 이끄는 역할을 한다.
                                    </p>
                                </li>
                                <li>
                                    <p className="right_sub_txt">학습은 결국 부정적인 감정들로 인하여 버려지고 망쳐지는 것이다</p>
                                </li>
                                <li>
                                    <p className="right_sub_txt">교수학습시스템은 즐거움과 흥미를 극대화하고 고통을 최소화해야한다.</p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </section>
            <section className="emotion_section2">
                <div className="com_inner section2_inner">
                    <h3 className="section_title blue">학습 감정 + 인지 미세 측정 시스템</h3>
                    <div className="section2_box">
                        <img src="/image/emotion-section2.png" alt="" />
                        <ul>
                            <li>
                                <p className="section2_txt">데이터에 근거한 학습활동 분석과 적용, 제공 및 활용</p>
                            </li>
                            <li>
                                <p className="section2_txt">학습자별, 학습자 간 감정, 주의 프로파일 비교 및 분석 활용</p>
                            </li>
                            <li>
                                <p className="section2_txt">
                                공통 감정 요소를 끌어내는 내용을 확인, 감성적 교과과정 설계 및 개발, 수정 및 보완에 활용
                            </p>
                            </li>
                            <li>
                                <p className="section2_txt">감정과 시선을 반영한 보다 자연스럽고 상황에 적합한 피드백 시스템</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="emotion_section3">
                <div className="com_inner section3_inner">
                    <h3 className="section_title blue">
                        다양한 학습자 (시청자)의 감정과 효과 분석
                    </h3>
                    <div className="section3_box">
                        <img src="./image/emotion_effect-1.png" alt="" />
                        <div className="effect_txt_wrap">
                            <ul>
                                <li className="effect_txt1">
                                    <h4 style={{ color: "#6c72ff" }}>다변화 회귀모형</h4>
                                    <p>7가지 감정별로 주의집중에 미치는 영향을 추정</p>
                                </li>
                                <li className="effect_txt2">
                                    <h4 style={{ color: "#16a9dd" }}>매개조절효과모형</h4>
                                    <p>
                                        7가지 감정과 주의집중이 학업성취에 대해 갖는 매개, 조절효과분석을
                                        확인하여 인과관계를 추정하는 모델링 방식
                                    </p>
                                </li>
                                <li className="effect_txt3">
                                    <h4 style={{ color: "#c759f4" }}>정준상관모형</h4>
                                    <p>
                                        7가지 감정과 주의집중이 학업성취에 대해 갖는 매개, 조절효과분석을
                                        확인하여 인과관계를 추정하는 모델링 방식
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="exam_text_box">
                            <p className="exam_tit">
                                [ Regression Model for Individuals 분석 예시 ]
                            </p>
                            <span className="exam_txt">대상자 : 20명</span>
                            <span className="exam_txt">온라인 개별 학습 진행</span>
                            <span className="exam_txt">감정 및 기타 로그 분석</span>
                            <span className="exam_txt">개별 Visual-Engagement 분석 결과</span>
                        </div>
                    </div>
                </div>
            </section>
            <section className="emotion_section4">
                <div className="com_inner section4_inner">
                    <video src="./image/emotion.mov" controls width="1180px" />
                </div>
            </section>
            <section className="com_experience">
                <img src="./image/emotion_bottom_image-1-modify.png" alt="" />
                <button type="button" className="btn_demo margin_top">
                Coming Soon
                </button>
            </section>
        </div>
    );
}

export default Emotion;